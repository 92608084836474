/* eslint-disable max-len */
import baseConfig from '../../src/config/baseConfig';

const config = {
  ...baseConfig,
  version: 351,
  env: 'prod',
  api: {
    ...baseConfig.api,
    middlewareAPIURL: process.env.MIDDLEWARE_API_URL || 'https://ats-api.talent-funnel.com/ats',
    jsAPIURL: process.env.REACT_APP_MIDDLEWARE_API_URL || 'https://ats-api.talent-funnel.com/js',
  },
  datadog: {
    ...baseConfig.datadog,
    sampleRate: 50,
    sessionReplaySampleRate: 20,
  },
  ga: {
    measurementId: 'G-ESMB5ZQDF2',
  },
  web: {
    formsWebUrl: process.env.FORMS_WEB_URL || 'https://forms.talent-funnel.com/form',
    bookingInWebUrl: process.env.BOOKING_IN_WEB_URL || 'https://booking.talent-funnel.com/invite',
    bookingInInterviewUrl: process.env.BOOKING_IN_WEB_URL || 'https://booking.talent-funnel.com/interview',
  },
  auth: {
    ...baseConfig.auth,
    applicationId: process.env.CLIENT_ID || 'db18b8d1-4464-4d4b-9519-4c460bfcb3f5',
    tenantId: process.env.TENANT_ID || 'dc4d1541-abfb-4699-82e2-414b4255e2a1',
  },
  publishing: {
    ...baseConfig.publishing,
    vacancyFeedUrl: process.env.VACANCY_FEED_URL || 'https://feeds.talent-funnel.com',
  },
  forms: {
    ...baseConfig.forms,
    genericStylingForm:
      process.env.QUESTION_SEARCH_PATH ||
      'https://forms.talent-funnel.com/form?id=c70e31fc-40d0-44a9-9d04-fbe7be15ac86&devMode=true',
  },
};

export default config;
